import React from 'react'
import Footer from './home/Footer'

const Contact = (props) => {
  return (
    <div style={{display: 'flex', flexDirection: 'horizontal'}}>
      <div style={{flex: 2/3, textAlign: 'center'}}>
        <div>
          <br />
          <br />
          <br />
        </div>          
        <div style={{display: 'flex', textAlign: 'center'}}>

            <div style={{flex: 1}}>
              <h1 style={{display: 'inline-block'}} className="partialUnderline">Kontakt</h1>
              <p>Zahnarztpraxis</p>
              <p>Dr. med. dent. Matthias Hofmann<br />
              Romanshornerstrasse 43<br />
              9320 Arbon<br />
              +41 (0)71 446 23 11<br />
              <a href="mailto:info@par-o-dent.com">info@par-o-dent.com</a></p>
            </div>

            <div style={{flex: 1}}>
              <h1 style={{display: 'inline-block'}} className="partialUnderline">Anfahrt</h1>
              <p>
                <b>Zug</b> <br />
                Station Arbon Seemoosried <br />
                (10min zu Fuss)
              </p>
              <p style={{marginTop: 30}}>
                <b>Bus</b> 200/940/941 <br />
                Station Romanshornerstrasse <br />
                (1min zu Fuss)
              </p>
            </div>
          </div>
      </div>

      <div style={{flex: 1/3}}>
        <iframe width="100%" height="500" 
        src="https://maps.google.com/maps?q=par-o-dent&t=&z=13&ie=UTF8&iwloc=&output=embed" 
        frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
      </div>
    </div>
  )
}

export default Contact

