
import React from 'react'
import { Link, withRouter } from 'react-router-dom'

function TopBar() {
  return (
    <div style={{
        height: 50, 
        lineheight: 50, 
        width: '100%', 
        backgroundColor: '#0B2F41', 
        color: 'white', 
        textAlign: 'right',
      }}>
        <div className="topBarElement">
          Mo - Fr 7:30 - 17:30
        </div>
        <div className="topBarElement">
          +41 (0)71 446 23 11
        </div>
        <div style={{marginRight: 40}} className="topBarElement">
          <a style={{color: 'white'}} href="mailto:info@par-o-dent.com">info@par-o-dent.com</a>
        </div>
    </div>
  )
}

function getLinkStyle(currentPath, linkPath) {
  let isThat = currentPath == linkPath ? true : false;
  return {
    fontWeight: isThat ? 'bold' : 'normal', 
    padding: 5, 
    border: isThat ? '1px solid rgb(29, 175, 206)' : null, 
    color: isThat ? '#1dafce' : 'black'
  }
}

class Nav extends React.Component {
  render() {

    const currentPath = this.props.location.pathname
    return (
      <nav style={{
        width: '100%', 
        minWidth: 1000,
        position: 'fixed', 
        top: 0,
        borderBottom: '1px solid #ccc'
        }}>
  
        <TopBar />
  
        <div style={{height: 70, textAlign: 'right', backgroundColor: 'white'}}>
          <img src="/assets/logo.png" style={{height: 45, float: 'left', marginLeft: 20, marginTop: 17.5}} />
          <div className="menuElement">
            <Link style={getLinkStyle(currentPath, '/')} to="/">Home</Link>
          </div>
          <div className="menuElement">
            <Link style={getLinkStyle(currentPath, '/ueberuns')} to="/ueberuns">Über uns</Link>
          </div>
          <div className="menuElement">
            <Link style={getLinkStyle(currentPath, '/leistungen')} to="/leistungen">Leistungen</Link>
          </div>
          <div  className="menuElement">
            <Link style={getLinkStyle(currentPath, '/team')} to="/team">Team</Link>
          </div>
          <div className="menuElement" style={{marginRight: 40}}>
            <Link style={getLinkStyle(currentPath, '/kontakt')} to="/kontakt">Kontakt</Link>
          </div>
        </div>
      </nav>
    )
  }

}

export default withRouter(Nav);