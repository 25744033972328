
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Nav from './components/Nav';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import Team from './pages/Team';
import Footer from './pages/home/Footer';

const App = (props) => {
  return (
    <Router>
      <div style={{marginTop: 120}}>
        <Switch>
          <Route path="/ueberuns"><AboutUs /></Route>
          <Route path="/leistungen"><Services /></Route>
          <Route path="/team"><Team/></Route>
          <Route path="/kontakt"><Contact isDetailPage /></Route>
          <Route path="/"><Home /></Route>
        </Switch>
        <Nav router={Router} />
        <Footer />
      </div>
    </Router>
  )
}

export default App;