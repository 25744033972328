import React from 'react';
import Contact from './Contact';
import Footer from './home/Footer';
import { Link } from "react-router-dom";
import Leistungen from './home/leistungen';
import './home.scss';
import Notfaelle from "./home/notfaelle";

function See() {
  return (
    <div style={{
      backgroundImage: 'url(/assets/see.jpg)',
      backgroundSize: 'cover',
      height: 700
      }} >

        <div style={{
          width: '50vw', 
          height: '25h', 
          backgroundColor: 'white', 
          position: 'absolute',
          right: 0,
          top: 300,
          opacity: 0.7
          }}>
            <div style={{margin: 30}}>
              <h1>Zahnarztpraxis</h1>
              <h1>Dr. med. dent. Matthias Hofmann</h1>
              <h2 style={{fontWeight: 500, marginTop: 50}}>Ihr Zahnarzt am Bodensee.</h2>
            </div>
        </div>

    </div>
  )
}

const Home = (props) => {
  return (
    <div>
      <See />
      {/* <Team /> */}
      <Leistungen />
      <Notfaelle />
      <Contact />
    </div>
  )
}

export default Home
