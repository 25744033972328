
import React from 'react'

function Footer() {
  return (
    <div style={{backgroundColor: '#EDF0F2', color: '#929292'}}>
      <div style={{padding: '50px 0 50px 50px', width: 200, display: 'inline-block'}}>
        Öffnungszeiten <br /><br />
        Mo - Fr 7:30 - 17:30
      </div>
      <div style={{padding: '50px 0 50px 50px', width: 200, display: 'inline-block'}}>
        Telefonnummer <br /><br />
        +41 (0)71 446 23 11
      </div>
      <div style={{padding: '50px 0 50px 50px', width: 200, display: 'inline-block'}}>
        Email <br /><br />
        <a style={{color: '#929292'}} href="mailto:info@par-o-dent.com">info@par-o-dent.com</a>
      </div>
      <div style={{textAlign: 'right', padding: '0 50px 50px 0'}}>
        © 2020 PAR-O-DENT AG 
      </div>
    </div>
  );
}

export default Footer;