
import React from 'react'

let getTabElStyle = (current, thatOne) => { return {
  cursor: 'pointer', 
  flex: 1, 
  backgroundColor: current == thatOne ? 'white' : '#0B2F41', 
  textAlign: 'center',
  padding: '120px 5px 120px 10px',
  color: current == thatOne ? '#1dafce' : 'white'
}};

class Services extends React.Component {
  state = {
    tabIndex: 0
  }
  render() {
    return (
      <div>
        <h1 style={{padding: '50px 50px 0 50px'}}>
          Leistungen
        </h1>
        <div style={{display: 'flex', fontSize: 23}}>
          <div 
            onClick={() => this.setState({tabIndex: 0})}
            style={getTabElStyle(this.state.tabIndex, 0)}>
              Dentalhygiene <br /> <i>Prophylaxe</i>
          </div>
          <div 
            onClick={() => this.setState({tabIndex: 1})}
            style={getTabElStyle(this.state.tabIndex, 1)}>
              Ästhetik
          </div>
          <div 
            onClick={() => this.setState({tabIndex: 2})}
            style={getTabElStyle(this.state.tabIndex, 2)}>
              Chirurgie
          </div>
          <div 
            onClick={() => this.setState({tabIndex: 3})}
            style={getTabElStyle(this.state.tabIndex, 3)}>
              Rekonstruktive  Zahnmedizin
          </div>
          <div 
            onClick={() => this.setState({tabIndex: 4})}
            style={getTabElStyle(this.state.tabIndex, 4)}>
              Wurzelbehandlung <br /> <i>Endodontologie</i>
          </div>
        </div>


        {this.state.tabIndex == 0 && 
          <div style={{display: 'flex'}}>
            <div style={{flex: 3}}>
              <p style={{margin: 50}}>
                Damit die Gesundheit und Optik Ihrer Zähne und des Zahnfleischs erhalten bleibt, 
                ist eine professionelle Zahnreinigung unabdingbar. Dies sollte in regelmässigen Abständen 
                durchgeführt werden, damit man Erkrankungen vorzeitig erkennen und behandeln kann.
              </p>
            </div>
            <div style={{flex: 2, backgroundImage: 'url(assets/smile.jpg)', height: 300, backgroundSize: 'cover'}}>
            </div>
          </div>
        }


        {this.state.tabIndex == 1 && 
          <div style={{display: 'flex'}}>
            <div style={{flex: 3}}>
              <div style={{margin: 50}}>
                <p>
                  Ästhetische Zahnbehandlungen ermöglichen ein strahlendes Lächeln.
                </p>
                <p>
                  Durch <b>Bleaching</b> können die Zähne chemisch aufgehellt werden. 
                  Normalerweise dauert eine Sitzung 60 bis 90min.
                </p>
              </div>
            </div>
            <div style={{flex: 2, backgroundImage: 'url(assets/smile.jpg)', height: 300, backgroundSize: 'cover'}}>
            </div>
          </div>
        }


        {this.state.tabIndex == 2 && 
          <div style={{display: 'flex'}}>
            <div style={{flex: 3}}>
              <div style={{margin: 50}}>
                <p>
                  Mit Frau Dr. Johanna Braun wurde das Praxisteam im Mai 2020 mit einer Fachzahnärztin 
                  für Oralchirugie ergänzt. Im Rahmen der Chirurgie bieten wir folgende Leistungen an:
                </p>
                <ul>
                  <li>
                    <b>Implantologie</b> <br /><br />
                    Durch implantatgestützte Lösungen kann ein Zahn am besten in seiner natürlichen 
                    Art und Weise wieder hergestellt werden.
                    <br /><br />
                    Zahnimplantate sind eine Möglichkeit Zahnwurzeln künstlich zu imitieren. Damit 
                    können fest sitzende Kronen und Brücken hergestellt werden und gegebenenfalls kann 
                    der Halt von Prothesen verbessert werden. <br /><br />
                    Diese Behandlung bietet eine langfristige Lösung bei der ein hohes Mass an 
                    Ästhetik gewährleistet ist.
                  </li>
                  <li>
                    <b>Weisheitszahnbehandlungen</b> <br/><br/>
                    Alle Arten von Weisheitszähnen werden bei uns fachkundig entfernt.
                  </li>
                  <li>
                    <b>Wurzelspitzenresektion</b> <br/><br/>
                      Eine Wurzelspitzenresektion ist ein Versuch den Zahn bei entzündlichen 
                      Prozessen an der Wurzelspitze zu erhalten.
                  </li>
                </ul>

              </div>
            </div>
            <div style={{flex: 2, backgroundImage: 'url(assets/werkzeug.jpg)', backgroundSize: 'cover'}}>
            </div>
          </div>
        }

{this.state.tabIndex == 3 && 
          <div style={{display: 'flex'}}>
            <div style={{flex: .7, backgroundImage: 'url(assets/stuhl.png)', backgroundSize: 'cover'}}>
            </div>
            <div style={{flex: 1, padding: 50}}>
              <p>
                Es gibt Prothesen in zwei Formen: abnehmbar und festsitzend
              </p>
              <ul>
                <li>
                  <b>Abnehmbare Prothetik</b> bestehen aus Teil-, Hybrid-, 
                  Total-und Immediat-Prothesen oder als Übergangslösung Platten Provisorien. 
                  Alterszahnmedizin gehört auch in diese Kategorie.
                </li>
                <li>
                    <b>Zu festsitzendem Zahnersatz</b> zählen Kronen, Brücken, Teilkronen, 
                  Kronen auf Implantaten und Veneers (Keramikverblendschalen welche “unschöne” 
                  Stellen verdecken). Keramik-und Komposit-Füllungen werden nach Kariesbehandlungen 
                  verwendet und lassen die Zähne wieder strahlen.
                </li>
              </ul>
            </div>
          </div>
        }

{this.state.tabIndex == 4 && 
          <div style={{display: 'flex'}}>
            <div style={{flex: .7, backgroundImage: 'url(assets/stuhl.png)', backgroundSize: 'cover'}}>
            </div>
            <div style={{flex: 1, padding: 50}}>
              <p>
                Unter einer Wurzelkanalbehandlung versteht man die Behandlung des Zahninneren. 
                Diese Behandlung ist nötig um abgestorbene oder entzündete Zähne zu erhalten. 
                Die Ursache dafür sind fast immer Bakterien aus einer unbehandelten Karies. 
                Aber auch wiederholte Zahnbehandlungen, Risse oder Unfälle können eine 
                solche Entzündung verursachen.
              </p>
              <p>Typische Anzeichen für eine Entzündung der Zahnpulpa sind:</p>
              <ul>
                <li>Verstärkte Empfindlichkeit auf Temperatur (Wärme/Kälte)</li>
                <li>Zahnschmerzen beim Kauen</li>
                <li>Grauverfärbung der Zahnkrone</li>
                <li>Schmerzen und/oder Schwellung im Bereich der Zahnwurzel</li>
              </ul>
              <p>
                Der Zahnnerv wird mit speziellen Instrumenten entfernt und die Funktion des Zahnes so 
                teilweise wiederhergestellt. Er muss somit nicht gezogen werden.
              </p>
            </div>
          </div>
        }

      </div>
    );
  }
}

export default Services