
import React from 'react'

class AboutUs extends React.Component {
  state = {tabIndex: 0}
  render() {
    return (
      <div className="">
        <div style={{width: '100vw', height: 280, backgroundImage: 'url(assets/ueberuns.png)', backgroundSize: 'cover'}}>
          <div style={{padding: 50}}>
            <h1 style={{color: 'white', display: 'inline-block'}} className="partialUnderlineWhite">
              Über uns
            </h1>
          </div>
        </div>
        <div style={{display: 'flex', height: 90}}>
          <div onClick={() => {this.setState({tabIndex: 0})}} style={{flex: 1, lineHeight: '90px', textAlign: 'center', fontSize: 30, fontWeight: 'bold', cursor: 'pointer', backgroundColor: 0 == this.state.tabIndex ? 'white' : '#0B2F41', color: 0 == this.state.tabIndex ? '#1dafce' : 'white'}}>Notfälle</div>
          <div onClick={() => {this.setState({tabIndex: 1})}} style={{flex: 1, lineHeight: '90px', textAlign: 'center', fontSize: 30, fontWeight: 'bold', cursor: 'pointer', backgroundColor: 1 == this.state.tabIndex ? 'white' : '#0B2F41', color: 1 == this.state.tabIndex ? '#1dafce' : 'white'}}>Angstpatienten</div>
          <div onClick={() => {this.setState({tabIndex: 2})}} style={{flex: 1, lineHeight: '90px', textAlign: 'center', fontSize: 30, fontWeight: 'bold', cursor: 'pointer', backgroundColor: 2 == this.state.tabIndex ? 'white' : '#0B2F41', color: 2 == this.state.tabIndex ? '#1dafce' : 'white'}}>Technologien</div>
        </div> 

        <div style={{display: 'flex'}}>
          {this.state.tabIndex == 0 && 
            <div className="pageContent" style={{display: 'flex'}}>
              <div style={{flex: 1/3}}>
                <p style={{paddingRight: 20, textAlign: 'justify'}}>
                  Wir lassen Sie in Notfallsituationen nicht im Stich. 
                  Bei uns können Sie Termine kurzfristig vereinbaren, da wir immer auf solche Fälle vorbereitet sind. 
                  Rufen Sie uns an: <b>071 446 23 11</b>
                </p>
              </div>
              <div style={{flex: 2/3}}>
                <p style={{padding: '0 10px 0 10px'}}>
                  So verhalten Sie sich nach Zahnunfällen richtig: <br />
                    1. Pressen Sie ein Stofftuch gegen die Zahnlücke, um die starke Blutung zu stoppen <br />
                    2. Legen Sie den oder die herausgeschlagenen Zähne in Milch ein (nicht reinigen)! <br />
                    3. Kontaktieren Sie umgehend einen Zahnarzt
                </p>
              </div>
            </div>
          }


          {this.state.tabIndex == 1 && 
            <div className="pageContent" style={{display: 'flex'}}>
              <div style={{flex: 1, paddingRight: 20, textAlign: 'justify'}}>
                <p style={{}}>
                  Wir sind uns bewusst, dass für einige Patienten ist der Besuch beim Zahnarzt eine unbehagliche Angelegenheit ist. Diese Angst kann viele Gründe haben, zum Beispiel kann sie durch negative Erfahrungen, starke Schmerzen oder die Ungewissheit über Vorgehensweisen hervorgerufen werden. <br />
                  Falls aus Sie sich unwohl bis extrem gestresst beim Gedanken an einen Zahnarzttermin fühlen, sind Sie bei uns in guten Händen. Wir nehmen uns Zeit und erklären Ihnen gerne alle Schritte vor der Behandlung. Ausserdem können durch neuste Technologien, gewisse Behandlungen sogar schmerzfrei durch geführt werden.              </p>
              </div>
              <div style={{flex: 2}}>
                <p style={{padding: '0 10px 0 10px'}}>
                Es besteht die Möglichkeit für eine <b>Vollnarkosebehandlung</b> in der Praxis, was ideal für Kinder, 
                Menschen mit körperlichen oder geistigen Beschwerden und Erwachsene mit “Dentalphobie” 
                (Angst vor zahnärztlichen Behandlungen) ist. <br /> <br />
                Falls Sie Fragen dazu haben, wenden Sie sich gerne an uns.              </p>
              </div>
            </div>
          }


          {this.state.tabIndex == 2 && 
            <div className="pageContent" style={{display: 'flex'}}>
              <div style={{flex: 1}}>
                <p style={{ textAlign: 'justify', paddingRight: 20, wordWrap: 'break-word', textJustify: 'inter-character' }}>
                  Uns ist es wichtig auf dem neusten Stand der Dinge zu sein. 
                  Darum besuchen wir jährlich verschiedene Weiterbildungen, um unsere langjährige Erfahrung mit neuen 
                  technologischen Fortschritten zu verbinden. <br /> <br />
                  Gewisse Behandlungen können mit Hilfe unserer neuen <b>Lasergeräten</b> weitgehend schmerzfrei und ohne Anästhesie (Betäubung) durchgeführt werden. Einige Beispiele sind Kariesbehandlungen, Schleimhautbehandlung nach implantatologischer Versorgung, Fissurenversieglungen, Aphten und Herpesbehandlungen.            
                </p>
              </div>
              <div style={{flex: 2}}>
                <p style={{lineHeight: '30px', padding: '0 10px 0 10px'}}>
                  Computergestützte <b>digitale Röntgentechnologie</b> ermöglicht ausserdem eine schmerzfreie, strahlungsarme und schnelle Diagnose.              </p>
              </div>
            </div>
          }

        </div>



      </div>


    )
  }
}

export default AboutUs