import React from 'react';
import { Link } from "react-router-dom";
import './notfaelle.scss';

function Notfaelle() {
    return (
        <div className="notfaelleGrid">
            <div className="textItem notfaelle">
                <h1 className="partialUnderline">Notfälle</h1>
                <p>
                    Wir lassen Sie in Notfallsituationen nicht im Stich.
                    Bei uns können Sie Termine kurzfristig vereinbaren,
                    da wir immer auf solche Fälle vorbereitet sind. <br/>
                    Rufen Sie uns an: <b>071 446 23 11</b>
                </p>
                <Link className="button" to="/leistungen">Weiterlesen</Link>
            </div>
            <div className="textItem angstpatienten">
                <h1 className="partialUnderline">Angstpatienten</h1>
                <p>
                    Wir sind uns bewusst, dass für einige Patienten ist der
                    Besuch beim Zahnarzt eine unbehagliche Angelegenheit ist.
                    Wir haben besondere Erfahrung mit Angstpatienten.
                </p>
                <Link className="button" to="/leistungen">Weiterlesen</Link>
            </div>
            <div className="imageItem image1" />
            <div className="imageItem image2" />
            <div className="textItem technologien">
                <h1 className="partialUnderlineWhite">Neuste Technologien</h1>
                <p>
                    Uns ist es wichtig auf dem neusten Stand der Dinge zu sein.
                    Darum besuchen wir jährlich verschiedene Weiterbildungen,
                    um unsere langjährige Erfahrung mit neuen technologischen
                    Fortschritten zu verbinden.
                </p>
                <Link className="button" to="/leistungen">Weiterlesen</Link>
            </div>
        </div>

    )
}

export default Notfaelle;