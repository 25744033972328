import React from 'react';
import './leistungen.scss';
import { Link } from "react-router-dom";

function Leistungen() {
    return (
        <div className="leistungenBar">
            <div style={{textAlign: 'left', width: '100%', marginLeft: 80}}>
                <h1 style={{color: 'white', display: 'inline-block'}}>Leistungen im Überblick</h1>
            </div>
            <div className="leistungenElements">
                <div className="leistungenElement">
                    <img className="icon" src="assets/pages/home/leistungen/zahnbuerste_icon.png" />
                    <div className="header">Dentalhygiene <br /> & Vorsoge</div>
                    <div className="subheader"><i>Prophylaxe</i></div>
                </div>
                <div className="leistungenElement">
                    <img className="icon" src="assets/pages/home/leistungen/zahn_icon.png" />
                    <div className="header">Ästhetik</div>
                </div>
                <div className="leistungenElement">
                    <img className="icon" src="assets/pages/home/leistungen/chirugie_icon.png" />
                    <div className="header">Implantate</div>
                </div>
                <div className="leistungenElement">
                    <img className="icon" src="assets/pages/home/leistungen/anker_icon.png" />
                    <div className="header">Wurzelbehandlung</div>
                </div>
            </div>
            <div>
                <Link to="/leistungen" className="buttonInverted">Weiterlesen</Link>
            </div>
        </div>
    )
}
export default Leistungen