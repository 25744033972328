
import React from 'react'

const Team = (props) => {
  return (
    <div className="pageContent" style={{backgroundColor: '#EDF0F2', borderBottom: '1px solid #ccc'}}>
      <div style={{textAlign: 'center'}}>
        <h1 style={{textAlign: 'center', display: 'inline-block'}} className="partialUnderlineBlack">Das Praxisteam</h1>
      </div>
      <div style={{textAlign: 'center'}}>


        <div style={{textAlign: 'center', margin: 50, display: 'inline-block'}}>
          <img src="assets/team/matthias.jpg" style={{width: 150, height: 150, borderRadius: 100}}></img>
          <p>Dr. Matthias Hofmann <br /> Zahnarzt</p>
        </div>

        <div style={{textAlign: 'center', margin: 50, display: 'inline-block'}}>
          <img src="assets/team/johanna.jpg" style={{width: 150, height: 150, borderRadius: 100}}></img>
          <p>Dr. Johanna Braun <br /> Zahnärztin</p>
        </div>

        <div style={{textAlign: 'center', margin: 50, display: 'inline-block'}}>
          <img src="assets/team/jacqueline.jpg" style={{width: 150, height: 150, borderRadius: 100}}></img>
          <p>Jacquline Ritzmann <br /> Sekretärin</p>
        </div>

      </div>
      <div style={{textAlign: 'center'}}>

        <div style={{textAlign: 'center', margin: '20px 50px 50px 0', display: 'inline-block'}}>
          <img src="assets/team/nathalie.jpg" style={{width: 150, height: 150, borderRadius: 100}}></img>
          <p>Natalie Trinidad <br /> Prophylaxe Assistentin</p>
        </div>

        <div style={{textAlign: 'center', margin: 50, marginTop: 20, display: 'inline-block'}}>
          <img src="assets/team/shirin.jpg" style={{width: 150, height: 150, borderRadius: 100}}></img>
          <p>Shirin Peischl <br /> Dentalassistentin</p>
        </div>

        <div style={{textAlign: 'center', margin: 50, marginTop: 20,  display: 'inline-block'}}>
          <img src="assets/team/blarta.jpg" style={{width: 150, height: 150, borderRadius: 100}}></img>
          <p>Blerta Mustafi <br /> Dentalassistentin</p>
        </div>

        <div style={{textAlign: 'center', margin: '20px 0 50px 50px', display: 'inline-block'}}>
          <img src="assets/team/muriel.jpg" style={{width: 150, height: 150, borderRadius: 100}}></img>
          <p>Muriel Semere <br /> Lehrnende Dentalassistentin</p>
        </div>

      </div>

      <hr />
      <br />
      <br />

      <div style={{textAlign: 'center'}}>
        <div style={{width: 1200, display: 'inline-block'}}>
          <div style={{display: 'flex'}}>

          <div style={{flex: 0.7}}>
            <div style={{padding: 20}}>
              <img src="/assets/team.jpeg" style={{
                width: '100%',
                border: '5px solid white',
                boxShadow: '5px 5px 7px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)'  
                }}/>
            </div>
          </div>

          <div style={{flex: 1, textAlign: 'left'}}>
            <div style={{padding: '5px 20px 0px 30px'}}>
              <p style={{textAlign: 'justify'}}>
                  Uns ist es wichtig Ihren Besuch so stressfrei wie möglich zu gestalten und Wartezeiten zu vermeiden. 
                  Deshalb setzen wir auf eine gute Organisation sowie eine offene Kommunikation, um auf 
                  Ihre individuellen Wünsche und Bedürfnisse einzugehen. 
                  Dies ermöglicht uns jede Behandlung den Notwendigkeiten des Patienten anzupassen, 
                  wobei wir besondere Erfahrung mit Angstpatienten mitbringen. <br />
                  Durch neueste Technologien ist es uns nicht nur möglich gewisse Behandlungen schmerzfrei 
                  und ohne Spritzen durchzuführen, sondern auch mit der Zeit zu gehen und den steigenden Qualitätsansprüchen 
                  nachzukommen. Dazu zählt ebenso unsere Anforderung an Schweizer Qualität bezüglich Zahntechnik 
                  (Kronen, Brücken, Prothesen, etc.). <br />
                  Mit unserer langjährigen Erfahrung und einer positiven Praxiskultur, sind wir ein zuverlässiges 
                  und kompetentes Team, wobei Ihre Zufriedenheit an erster Stelle steht.
              </p>
              <p>Wir freuen uns auf Ihren Besuch in Arbon!</p>
            </div>
          </div>



          </div>
        </div>
        <br />
        <br />
      </div>

    </div>
  )
}

export default Team;

